import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const LevelMixin = {
    data() {
        return {
            items: [],
            json_fields: {
                "Level": {
                    field: "level_name",
                },
                "Platform charge": {
                    field: "platform_charge",
                },
                "Score": {
                    field: "score",
                },
                "badge": {
                    field: "badge",
                },
                "Sm impression": {
                    field: "sm_impression",
                },
                "Suggestion": {
                    field: "suggestion",
                },
                "Get recognition": {
                    field: "get_recognition",
                },
                "Eoc 1": {
                    field: "eoc1",
                },
                "Eoc 2": {
                    field: "eoc2",
                },
                "Subscription": {
                    field: "subscription",
                },
                "Status": {
                    field: "status",
                },
            },
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    label: 'Level name',
                    key: 'level_name',
                    sortable: true,
                },
                {
                    label: 'Platform charge',
                    key: 'platform_charge',
                    sortable: true,
                },
                {
                    label: 'Score range',
                    key: 'score_start',
                    sortable: true,
                },
                {
                    label: 'Badge',
                    key: 'badge',
                    sortable: true,
                },
                {
                    label: 'Sm impression',
                    key: 'sm_impression',
                    sortable: true,
                },
                {
                    label: 'Suggestion',
                    key: 'suggestion',
                    sortable: true,
                },
                {
                    label: 'Get recognition',
                    key: 'get_recognition',
                    sortable: true,
                },
                {
                    label: 'Eoc 1',
                    key: 'eoc1',
                    sortable: true,
                },
                {
                    label: 'Eoc 2',
                    key: 'eoc2',
                    sortable: true,
                },
                {
                    label: 'Subscription',
                    key: 'subscription',
                    sortable: true,
                },
                {
                    label: 'Gamification',
                    key: 'gamification',
                    sortable: true,
                },
                {
                    label: 'Status',
                    key: 'status',
                    sortable: true,
                },
                {
                    label: 'Actions',
                    key: 'actions',
                },

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                bank_logo: '',
                bank_title: '',
                country_name: '',
                address: '',
                status: '',
            },
            filter: null,
            filterOn: [],
            bank_name_state: null,
            bank_address_state: null,
            bankImageState: null,

            level_name: '',
            platform_charge: '',
            score_start: '',
            score_end: '',
            badge: 1,
            sm_impression: '',
            suggestion: '',
            get_recognition: 1,
            eoc_1: 1,
            eoc_2: 1,
            subscription: 1,
            gamification: 1,
            status: 1,
            is_update: false,
            row_id: '',

        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Level", route: "/level"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 13)
        this.$root.$emit('call-active-menu', 13);
        this.getLevelList();
    },
    // metaInfo: {
    //     title: 'Orko Health Ltd | Bank List',
    //     htmlAttrs: {
    //         lang: 'en-US'
    //     }
    // },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                bank_logo: '',
                bank_title: '',
                country_name: '',
                address: '',
                status: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        getLevelList() {
            axios.get(`${process.env.VUE_APP_URL}/api/v2/level/levels?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data
                    }
                })
        },
        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../assets/img/blank.png";
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.bank_title = valid
            this.bank_name_state = valid
            this.bankImageState = valid
            this.bank_address_state = valid
            return valid
        },
        resetModal() {
            this.form = {};
            this.bank_name_state = null;
            this.bank_address_state = null;
            this.bankImageState = null;
            this.level_name = '';
            this.platform_charge = '';
            this.score = '';
            this.badge = '';
            this.sm_impression = '';
            this.suggestion = '';
            this.get_recognition = '';
            this.eoc_1 = '';
            this.eoc_2 = '';
            this.subscription = '';
            this.gamification = '';
            this.status = '';
            this.is_update = '';
            this.row_id = '';
            this.is_update = false;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            if(this.is_update){
                return this.updateSubmit();
            }
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names

            let form = new FormData();
            form.append("level_name", this.level_name);
            form.append("platform_charge", this.platform_charge);
            form.append("score_start", this.score_start);
            form.append("score_end", this.score_end);
            form.append("badge", this.badge);
            form.append("sm_impression", this.sm_impression);
            form.append("suggestion", this.suggestion);
            form.append("get_recognition", this.get_recognition);
            form.append("eoc1", this.eoc_1);
            form.append("eoc2", this.eoc_2);
            form.append("subscription", this.subscription);
            form.append("gamification", this.gamification);
            form.append("status", this.status);

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v2/level/levels?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getLevelList();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        edit(row) {
            this.$bvModal.show('modal-1')
            this.level_name = row.item.level_name;
            this.platform_charge = row.item.platform_charge;
            this.score_start = row.item.score_start;
            this.score_end = row.item.score_end;
            this.badge = row.item.badge;
            this.sm_impression = row.item.sm_impression
            this.suggestion = row.item.suggestion;
            this.get_recognition = row.item.get_recognition;
            this.eoc_1 = row.item.eoc1;
            this.eoc_2 = row.item.eoc2;
            this.subscription = row.item.subscription;
            this.gamification = row.item.gamification;
            this.status = row.item.status;
            this.is_update = true;
            this.row_id = row.item.id;

        },
        updateSubmit(){
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names

            let form = new FormData();
            form.append("level_name", this.level_name);
            form.append("platform_charge", this.platform_charge);
            form.append("score_start", this.score_start);
            form.append("score_end", this.score_end);
            form.append("badge", this.badge);
            form.append("sm_impression", this.sm_impression);
            form.append("suggestion", this.suggestion);
            form.append("get_recognition", this.get_recognition);
            form.append("eoc1", this.eoc_1);
            form.append("eoc2", this.eoc_2);
            form.append("subscription", this.subscription);
            form.append("gamification", this.gamification);
            form.append("status", this.status);

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v2/level/levels/${this.row_id}/?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getLevelList();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully updated');
                    })
            })
        }
    }
}
