<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h2>Level list</h2>
        <div class="row mt-10">
          <div class="col-md-12">
            <b-button variant="primary" class="btn-lg" v-b-modal.modal-1><i class="fas fa-plus"></i> Add new level
            </b-button>
            <b-modal
                id="modal-1"
                size="lg"
                title="Create a new level"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk">
              <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">
                <b-form-group
                    label-for="name-input"
                    invalid-feedback="Level name is required">
                  <label for="">Level name <span class="text-danger">*</span></label>
                  <b-form-input
                      id="name-input"
                      v-model="level_name"
                      required/>
                </b-form-group>

                <b-form-group
                    label-for="name-input"
                    invalid-feedback="Platform charge is required">
                  <label for="">Platform charge
                    <span class="text-danger">*</span></label>
                  <b-form-input
                      id="name-input"
                      v-model="platform_charge"
                      required/>
                </b-form-group>

                <b-form-group
                    label-for="name-input"
                    invalid-feedback="Score start is required">
                  <label for="">Score start
                    <span class="text-danger">*</span></label>
                  <b-form-input
                      id="name-input"
                      v-model="score_start"
                      />
                </b-form-group>

                <b-form-group
                    label-for="name-input"
                    invalid-feedback="Score start is required">
                  <label for="">Score end
                    <span class="text-danger">*</span></label>
                  <b-form-input
                      id="name-input"
                      v-model="score_end"
                      />
                </b-form-group>



                <b-form-group
                    label-for="name-input"
                    invalid-feedback="Score is required">
                  <label for="">Sm impression
                    <span class="text-danger">*</span></label>
                  <b-form-input
                      id="name-input"
                      v-model="sm_impression"
                      required/>
                </b-form-group>

                <b-form-group
                    label-for="name-input"
                    invalid-feedback="Score is required">
                  <label for="">Suggestion
                    <span class="text-danger">*</span></label>
                  <b-form-input
                      id="name-input"
                      v-model="suggestion"
                      required/>
                </b-form-group>

                <div class="row">
                  <div class="col-4">
                    <b-form-group label="Badge ?" class="font-weight-bold">
                      <b-form-radio v-model="badge" name="some-radios" value="1">Yes</b-form-radio>
                      <b-form-radio v-model="badge" name="some-radios" value="0">No</b-form-radio>
                    </b-form-group>
                  </div>


                  <div class="col-4">
                    <b-form-group label="Get recognition ?" class="font-weight-bold">
                      <b-form-radio v-model="get_recognition" name="some-radios5" value="1">Yes</b-form-radio>
                      <b-form-radio v-model="get_recognition" name="some-radios6" value="0">No</b-form-radio>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group label="Eoc 1 ?" class="font-weight-bold">
                      <b-form-radio v-model="eoc_1" name="some-radios7" value="1">Yes</b-form-radio>
                      <b-form-radio v-model="eoc_1" name="some-radios8" value="0">No</b-form-radio>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group label="Eoc 2 ?" class="font-weight-bold">
                      <b-form-radio v-model="eoc_2" name="some-radios9" value="1">Yes</b-form-radio>
                      <b-form-radio v-model="eoc_2" name="some-radios10" value="0">No</b-form-radio>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group label="Subscription ?" class="font-weight-bold">
                      <b-form-radio v-model="subscription" name="some-radios11" value="1">Yes</b-form-radio>
                      <b-form-radio v-model="subscription" name="some-radios12" value="0">No</b-form-radio>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group label="Gamification ?" class="font-weight-bold">
                      <b-form-radio v-model="gamification" name="some-radios13" value="1">Yes</b-form-radio>
                      <b-form-radio v-model="gamification" name="some-radios14" value="0">No</b-form-radio>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group label="Status ?" class="font-weight-bold">
                      <b-form-radio v-model="status" name="some-radios15" value="1">Active</b-form-radio>
                      <b-form-radio v-model="status" name="some-radios16" value="0">Inactive</b-form-radio>
                    </b-form-group>
                  </div>
                </div>
              </form>
            </b-modal>
          </div>
        </div>
        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <div>
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Level list"
                  name="Level list">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                class="table-responsive mt-10">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(score_start)="data">
                <span class="font-weight-bold">{{ data.item.score_start }} - {{ data.item.score_end}}</span>
              </template>

              <template #cell(badge)="data">
               <div>
                 <b-badge variant="success" v-if="data.item.badge == 1"><i class="fas fa-check text-white"></i></b-badge>
                 <b-badge variant="danger" v-else><i class="fas fa-times text-white"></i></b-badge>
               </div>
              </template>

              <template #cell(get_recognition)="data">
               <div>
                 <b-badge variant="success"  v-if="data.item.get_recognition == 1"><i class="fas fa-check text-white"></i></b-badge>
                 <b-badge variant="danger" v-else><i class="fas fa-times text-white"></i></b-badge>
               </div>
              </template>

              <template #cell(eoc2)="data">
               <div>
                 <b-badge variant="success" v-if="data.item.eoc2 == 1"><i class="fas fa-check text-white"></i></b-badge>
                 <b-badge variant="danger" v-else><i class="fas fa-times text-white"></i></b-badge>
               </div>
              </template>

              <template #cell(eoc1)="data">
               <div>
                 <b-badge variant="success" v-if="data.item.eoc1 == 1"><i class="fas fa-check text-white"></i></b-badge>
                 <b-badge variant="danger" v-else><i class="fas fa-times text-white"></i></b-badge>
               </div>
              </template>

              <template #cell(subscription)="data">
               <div>
                 <b-badge variant="success" v-if="data.item.subscription == 1"><i class="fas fa-check text-white"></i></b-badge>
                 <b-badge variant="danger" v-else><i class="fas fa-times text-white"></i></b-badge>
               </div>
              </template>

              <template #cell(gamification)="data">
               <div>
                 <b-badge variant="success" v-if="data.item.gamification == 1"><i class="fas fa-check text-white"></i></b-badge>
                 <b-badge variant="danger" v-else><i class="fas fa-times text-white"></i></b-badge>
               </div>
              </template>

              <template #cell(actions)="row">
                <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                            class="m-2">

                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>

                  <b-dropdown-item href="#" @click="edit(row)">
                    <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                  </b-dropdown-item>

                </b-dropdown>
              </template>

              <template #cell(status)="data">
                <div>
                  <b-badge variant="success" v-if="data.item.status == 1">Active</b-badge>
                  <b-badge variant="success" v-else>Inactive</b-badge>
                </div>
              </template>

              <template #cell(status)="data">
                <div>
                  <b-badge variant="success" v-if="data.item.status == 1">Active</b-badge>
                  <b-badge variant="success" v-else>Inactive</b-badge>
                </div>
              </template>

            </b-table>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                size="lg"
                class="my-0"
            ></b-pagination>


          </div>

        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {LevelMixin} from "../../../mixins/LevelMixin";

export default {
  name: "Level",
  mixins: [LevelMixin],
  title: 'Level',
}
</script>

<style scoped>

</style>